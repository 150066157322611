export type DICTKey = keyof typeof DICT

type ModuleLoader = () => Promise<any>

export interface DICTModule {
  src: ModuleLoader
  roots?: readonly string[]
}

export const DICT: Record<string, DICTModule> = {
  travel: {
    src: () => import('/~/extensions/travel/main'),
  },
  dining: {
    src: () => import('/~/extensions/dining/main'),
  },
  workplace: {
    roots: ['/rec-room'],
    src: () => import('/~/extensions/rec-room/main'),
  },
  estore: {
    src: () => import('/~/extensions/estore/main'),
  },
  'gift-cards': {
    src: () => import('/~/extensions/giftcards/main'),
  },
  'gift-cards-cinema': {
    src: () => import('/~/extensions/gift-cards-cinema/main'),
  },
  'online-offers': {
    src: () => import('/~/extensions/online-offers/main'),
  },
  competitions: {
    src: () => import('/~/extensions/competitions/main'),
  },
  'pre-sales': {
    src: () => import('/~/extensions/pre-sales/main'),
  },
  'community-hub': {
    src: () => import('/~/extensions/community-hub/main'),
  },
  qrconnect: {
    src: () => import('/~/extensions/qr-connect/main'),
  },
  health: {
    src: () => import('/~/extensions/health/main'),
  },
  'rec-room': {
    src: () => import('/~/extensions/rec-room/main'),
  },
  'bank-file-upload': {
    roots: ['/bank-file-upload', '/payments/bank-files'],
    src: () => import('/~/extensions/bank-file-upload/main'),
  },
  payroll: {
    roots: ['/payroll', '/payments/payroll'],
    src: () => import('/~/extensions/payroll/main'),
  },
  'lead-submission': {
    src: () => import('/~/extensions/lead-submission/main'),
  },
  'scheduled-payments': {
    roots: ['/scheduled-payments', '/payments/scheduled-payments'],
    src: () => import('/~/extensions/scheduled-payments/main'),
  },
  'ticket-master': {
    src: () => import('/~/extensions/ticket-master/main'),
  },
  otp: {
    src: () => import('/~/extensions/otp/main'),
  },
  'third-party-login': {
    src: () => import('/~/extensions/third-party-login/main'),
  },
} as const
