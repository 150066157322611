import Vue, { ref } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useProvider } from '/~/composables/provider'
import {
  Statement,
  StatementAccount,
  useStatementAccounts,
  useStatements,
} from '/~/composables/statements'
import router from '/~/router'
import { FlowType } from '../checkout/checkout-types'

const { deleteStatementAccount, fetchStatementAccounts } =
  useStatementAccounts()
const { fetchActiveStatements } = useStatements()

const confirmed = ref(false)

function getDescription(account: StatementAccount) {
  const { providerTitle } = useProvider()

  if (account.canRemove) {
    return `<div class="space-y-6">
      ${
        ui.mobile
          ? '<div>Customer Account No. <b> ' + account.number + ' </b> </div>'
          : ''
      }
      <div>Are you sure you want to remove your account? Please confirm your choice by clicking the "Confirm" button. Please note that this action cannot be undone and all upcoming transactions associated with the selected account will be permanently deleted.</div><div>
    If you wish to use this account again, you will need to go through the account approval process again.</div></div>`
  } else {
    return `<div class="text-eonx-neutral-800 space-y-6">
       <div>You are unable to remove the following statement account from ${providerTitle.value} at this time.</div>
      <div>Possible reasons for this restriction are as follows:</div>
      <ol class="list-decimal ml-5">
        <li>Unpaid Balance: There is a pending amount due associated with this account that needs to be settled before removal is permitted.</li>
        <li>Payment Processing: An ongoing payment transaction is being processed for this account, and removal is temporarily disabled until the process concludes.</li>
      </ol>
      <div>We apologise for any inconvenience caused by this restriction. If you have any questions or concerns regarding this matter, our support team is ready to assist you.</div>
      </div>`
  }
}

async function confirm(account: StatementAccount) {
  try {
    confirmed.value = false
    await deleteStatementAccount(account.id)
    await fetchStatementAccounts({
      includeAutoPayments: true,
      includeNextDueStatementOrders: true,
    })
    await fetchActiveStatements({
      'order[dueDate]': 'asc',
    })
    confirmed.value = true
  } catch (error) {
    Vue.notify({
      text: 'There was an error removing a customer account. Please try again.',
      type: 'error',
    })
  }
}

function removeStatementAccount(account: StatementAccount) {
  const description = getDescription(account)

  const removeAccountOptions = {
    props: {
      account,
      title: 'Remove account',
      description,
      icon: {
        path: 'heroicons/solid/trash',
        color: 'text-red-700',
      },
      showConfirm: account.canRemove,
      onConfirm: async () => {
        await confirm(account)
      },
    },
    on: {
      hide: () => {
        if (confirmed.value) {
          showRemovedAccountModal(account)
          confirmed.value = false
        }
      },
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', removeAccountOptions)
  } else {
    modal.show('remove-account', removeAccountOptions)
  }
}

function showRemovedAccountModal(account: StatementAccount) {
  const { providerTitle } = useProvider()

  const removeAccountOptions = {
    props: {
      account,
      title: 'Account removed successfully',
      description: `<div class="space-y-6 pr-2">
          ${
            ui.mobile
              ? '<div>Customer Account No. <b> ' +
                account.number +
                ' </b> </div>'
              : ''
          }
          <div>Your account information has been updated, you will no longer receive statements for account listed above in the ${
            providerTitle.value
          } platform. Any upcoming scheduled payments or direct debits associated with the removed account have been deleted.</div>
          <div>If you have any questions or concerns, please feel free to contact our support team.</div></div>`,
      icon: {
        path: 'heroicons/solid/check-circle',
        color: 'text-emerald-700',
      },
      showCancel: false,
      confirmLabel: 'Done',
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', removeAccountOptions)
  } else {
    modal.show('remove-account', removeAccountOptions)
  }
}

function showExistingScheduledPaymentModal(statement: Statement) {
  const { providerTitle } = useProvider()
  const { payment, currentFlowType } = useCheckoutReactive()
  const options = {
    props: {
      title: 'Existing Scheduled Payment',
      description: `<div class="space-y-6 pr-2">
          <div>A scheduled payment exists for this statement, set for the date of ${formatDate(
            'daymonthyearnumeric',
            statement.scheduledAt
          )}. You can choose to pay this statement now, and your scheduled payment will be overridden, or you can leave it be and the scheduled payment will occur on the set date.</div>
          <div>You can view and manage all scheduled payments at any time via the ‘Scheduled payments’ section of ${
            providerTitle.value
          }.</div></div>`,
      icon: {
        path: 'heroicons/solid/information-circle',
        color: 'text-emerald-700',
      },
      cancelLabel: ui.mobile ? 'Scheduled payments' : 'View scheduled payments',
      confirmLabel: 'Pay now',
      onConfirm: async () => {
        currentFlowType.value = FlowType.statement
        await payment.value.initPayment({
          statement: statement,
        })

        router.push({
          name: 'statement-checkout',
          params: {
            id: statement.id,
          },
        })
      },
      onCancel: () => {
        router.push('/payments/scheduled-payments/payments')
      },
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', options)
  } else {
    modal.show('confirm-v2', options)
  }
}

function showExistingScheduledPaymentModalOnceOff(
  onConfirm: () => Promise<void>
) {
  const options = {
    props: {
      title: 'Existing Scheduled Payment',
      description: `<div class="space-y-6 pr-2">
          <div>There is an existing once-off scheduled payment for this account. Enabling direct debit will cancel the payment.</div></div>`,
      icon: {
        path: 'heroicons/solid/information-circle',
        color: 'text-emerald-700',
      },
      cancelLabel: 'Cancel',
      confirmLabel: 'Continue',
      width: 'xse',
      onConfirm: async () => {
        await onConfirm()
        modal.hideAll()
      },
      onCancel: () => {
        modal.hideAll()
        if (ui.mobile) {
          router.push({ name: 'statements' })
        }
      },
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', options)
  } else {
    modal.show('confirm-v2', options)
  }
}

export function useStatementModals() {
  return {
    removeStatementAccount,
    showExistingScheduledPaymentModal,
    showExistingScheduledPaymentModalOnceOff,
  }
}
