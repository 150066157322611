import Decimal from 'decimal.js'
import type {
  RebatesCalculateProduct,
  CalculateRebateTotalByType,
  RewardsType,
} from '/~/types/api'
import api from '/~/core/api'
import { useEstore } from '/~/extensions/estore/composables'
import { useRetailer } from '/~/extensions/giftcards/composables'
import { roundFigure } from '/~/utils/format/numeric'
import OrderCheckout from '/~/composables/checkout/core/Checkout/OrderCheckout/OrderCheckout'
import { PaymentMethodType } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const { calculatePointsEarnedForPurchase } = usePoints()

type QuickBuyProduct = {
  id: string
  name: string
  productImage: string
  price?: number
  quantity: number
  value: number
  type: RewardsType
  retailerName?: string

  giftRecipientId?: string
  message?: string
  gift?: boolean

  physical?: boolean
  shippingFees?: number
  priceGst?: number
}

type Query = {
  slug: string
  offerExternalId: string
  externalId: string
  id: string
  price: string
  value: string
  quantity: number
  amount: string
  giftRecipientId: string
  message: string
  count: number
  productId: string
}

export class QuickBuyCheckout extends OrderCheckout {
  items: QuickBuyProduct[] = []
  billingAddressId: string | null = null
  shippingAddressId: string | null = null

  query?: Query
  type: RewardsType | null = null
  isQuickBuyInitializing = false
  rebateTotalByType: CalculateRebateTotalByType[] = []
  rebateProducts: RebatesCalculateProduct[] = []

  get cashbackRebateTotal() {
    return (
      this.rebateTotalByType.find((item) => item.type === 'cashback_rebate') ??
      0
    )
  }

  get quickBuyProducts() {
    return this.items
  }

  get checkoutItemsCount() {
    return this.items.length
  }

  get hasPhysicalItems() {
    return this.items.some((item) => item.physical)
  }

  get hasEstoreItems() {
    return this.items.some((item) => item.type === 'estore')
  }

  get hasDeliverableItems() {
    return this.hasPhysicalItems || this.hasEstoreItems
  }

  get isAddressSelectionAllowed() {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value || this.hasDeliverableItems
  }

  get shippingFees() {
    return this.items.reduce((value, item) => {
      return Number((item.shippingFees ?? 0) * item.quantity) + value
    }, 0)
  }

  get pointsEarned() {
    return calculatePointsEarnedForPurchase(
      this.items.map((item) => ({
        ...item,
        subValue: new Decimal(item.value).mul(item.quantity).toNumber(),
        rebates:
          this.rebateProducts.find((rebate) => rebate.id === item.id)
            ?.rebates ?? [],
      }))
    )
  }

  clearItems() {
    this.items = []
  }

  async initGiftCardItems() {
    this.isQuickBuyInitializing = true

    const {
      slug,
      offerExternalId,
      id,
      price,
      value,
      quantity,
      amount,
      giftRecipientId,
      message,
    } = this.query as Query

    const result = await this.getRetailerAndOffers(slug, offerExternalId)

    const [[, retailer], offer] = result
    const image = retailer?.attributes?.images?.opt_logo ?? ''
    const product = ((offer as any)?.relationships?.products?.data ?? []).find(
      (o: any) => o.attributes?.uuid === id
    )

    this.items = [
      {
        id,
        productImage: image,
        type: 'giftcard',
        price: roundFigure(Number(price || 0)),
        value: parseFloat(value || '0'),
        quantity: quantity || 1,
        name: product?.attributes.name ?? '',
        retailerName: retailer?.attributes?.name ?? '',
      },
    ]

    if (giftRecipientId) {
      this.items[0].giftRecipientId = giftRecipientId
      this.items[0].message = message
      this.items[0].gift = true
    }

    this.amount = parseFloat(amount || '0')
    this.isQuickBuyInitializing = false
  }

  async initEstoreItems() {
    this.isQuickBuyInitializing = true

    const { getEstoreItem } = useEstore()

    const { id, quantity, value } = this.query as Query
    const response: any = await getEstoreItem(id)
    const item = response.payload

    this.items = [
      {
        id,
        productImage: item.images[0],
        type: 'estore',
        priceGst: parseFloat(item.pricing.price_after_tax || 0),
        value: parseFloat(item.pricing.rrp_after_tax || 0),
        quantity: quantity || 1,
        name: item.name,
        retailerName: item.brand,
        shippingFees: parseFloat(item.shipping.shipping_after_tax || 0),
      },
    ]

    this.amount = parseFloat(value || '0')
    this.isQuickBuyInitializing = false
  }

  async initCinemaItems() {
    this.isQuickBuyInitializing = true

    const { slug, externalId, count, productId, amount } = this.query as Query
    const result = await this.getRetailerAndOffers(slug, externalId)
    const [[, retailer], offer] = result
    const image = retailer?.attributes?.images?.opt_logo ?? ''

    this.items = []

    if (productId) {
      const product = ((offer as any).relationships?.products?.data ?? []).find(
        (o: any) => o.id === productId
      )

      if (product) {
        const item: any = {
          id: product.attributes.uuid,
          productImage: image,
          type: 'voucher',
          price: roundFigure(Number(product.attributes.price || 0)),
          value: parseFloat(product.attributes.value || 0),
          quantity: count,
          name: product.attributes.name ?? '',
          retailerName: retailer?.attributes?.name ?? '',
        }

        this.items.push(item)
      }
    }

    this.amount = parseFloat(amount || '0')
    this.isQuickBuyInitializing = false
  }

  async initDiningItems() {
    this.isQuickBuyInitializing = true

    const { slug, offerExternalId, id, price, value, quantity, amount } = this
      .query as Query

    const result = await this.getRetailerAndOffers(slug, offerExternalId)

    const [[, retailer]] = result
    const image = retailer?.attributes?.images?.opt_logo ?? ''

    this.items = [
      {
        id,
        productImage: image,
        type: 'dining',
        price: roundFigure(Number(price || 0)),
        value: parseFloat(value || '0'),
        quantity: quantity || 1,
        name: retailer?.attributes?.name ?? '',
      },
    ]

    this.amount = parseFloat(amount || '0')
    this.isQuickBuyInitializing = false
  }

  async initItems(payload: any) {
    this.type = payload.type
    this.query = payload.query

    switch (this.type) {
      case 'estore':
        await this.initEstoreItems()
        break
      case 'giftcard':
        await this.initGiftCardItems()
        break
      case 'cinema':
        await this.initCinemaItems()
        break
      case 'dining':
        await this.initDiningItems()
        break
      default:
        throw new Error(`Extension type not found: ${this.type}`)
    }

    const { calculateRebates } = usePoints()
    const response = await calculateRebates(this.items)

    this.rebateTotalByType = response.rebateTotalByType ?? []
    this.rebateProducts = response.products ?? []
  }

  async verifyPromotionCode(promotionCode: string, amount: number) {
    this.isCouponVerifying = true

    try {
      const { data } = await api.post<any>(
        `/v3/promotion-codes/${promotionCode}/validate`,
        { amount: amount?.toString() }
      )

      if (data.promotionCode && data.amount > 0) {
        this.selectedCoupons = [
          ...this.selectedCoupons,
          { ...data, type: PaymentMethodType.couponCode },
        ]

        return [null, data]
      } else {
        const error = { message: 'Promotion code validation failed' }

        return [error]
      }
    } catch (error) {
      console.error(error)
      return [error]
    } finally {
      this.isCouponVerifying = false
    }
  }

  getRetailerAndOffers(slug: string, offer: any) {
    const { getRetailer, getRetailerOffers } = useRetailer()

    return Promise.all([getRetailer(slug), getRetailerOffers({ slug, offer })])
  }

  getAddAddressRoute() {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value
      ? { name: 'addresses-add' }
      : super.getAddAddressRoute()
  }

  getEditAddressRoute(id: string) {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value
      ? {
          name: 'addresses-edit',
          params: {
            id,
          },
        }
      : super.getEditAddressRoute(id)
  }

  getPayload() {
    const payload: any = {
      paymentSources: this.getPaymentSources(),
      products: this.items,
      billingAddressId: this.billingAddressId,
      shippingAddressId: this.shippingAddressId,
    }

    if (this.payWithCoupons > 0) {
      payload.promotionCodes = this.selectedCoupons
    }

    return payload
  }

  reset() {
    this.resetCouponsAndPaymentMethods()

    this.amount = 0
    this.submitting = false
    this.date = null
    this.address = null
    this.programFeesData = undefined
    this.programFees = 0
    this.loadingProgramFees = false
    this.description = null
    this.isSchedulingAllowed = true
    this.termsAccepted = false
    this.items = []
    this.billingAddressId = null
    this.shippingAddressId = null
  }

  get checkoutURL() {
    return '/v3/purchase-orders/quick-buy'
  }
}

export default QuickBuyCheckout
