import { computed, onMounted, watch } from 'vue'
import { KycServiceFactory } from '/~/extensions/otp/composables/core/Kyc/KycFactory'
import { useExtensions } from '/~/composables/extensions'

export function useKyc() {
  const { getConfigByName } = useExtensions()
  const moduleConfig = getConfigByName('user-kyc') as Kyc.ModuleConfig | null
  const isKycEnabled = computed(() => Boolean(moduleConfig))
  const serviceType = moduleConfig?.service ?? 'stripeId'
  const rolesAssignUponRegistration = (
    moduleConfig?.roleAssignUponRegistration ?? ''
  ).split(' ')

  return {
    isKycEnabled,
    serviceType,
    rolesAssignUponRegistration,
  }
}

export function useKycComponent() {
  const { serviceType, isKycEnabled } = useKyc()
  let kycService = KycServiceFactory.create(serviceType)

  watch(
    () => isKycEnabled,
    async (value) => {
      if (value) {
        kycService = KycServiceFactory.create(serviceType)
        await kycService.syncVerificationSessions()
      }
    }
  )

  onMounted(async () => {
    if (isKycEnabled.value) {
      await kycService.syncVerificationSessions()
    }
  })

  return {
    kycService,
  }
}
